import React from 'react'
import { Modal as ReactstrapModal, ModalHeader, ModalBody } from 'reactstrap'
import styles from './Modal.module.css'

const Modal = ({ isOpen, toggle, title, children }) => (
  <ReactstrapModal
    isOpen={isOpen}
    toggle={toggle}
    className={styles.modal}
  >
    <ModalHeader
      toggle={toggle}
      className={styles.modalHeader}
    >
      {title}
    </ModalHeader>
    <ModalBody className={styles.modalBody}>{children}</ModalBody>
  </ReactstrapModal>
)

export default Modal
