import axios from 'axios'
import { create } from 'zustand'
import { persist, devtools } from 'zustand/middleware'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const useAuthStore = create(
  devtools(
    persist(
      (set, get) => ({
        userData: JSON.parse(localStorage.getItem('user')) || null, // Carga inicial desde localStorage
        isLogin: localStorage.getItem('user') !== null, // Chequeo inicial de autenticación

        login: async (email, password) => {
          try {
            const response = await api.post('/login', { email, password })

            if (response.data?.access_token) {
              const userData = {
                access_token: response.data.access_token,
                info_user: response.data.info_user,
                role: response.data.role,
                isFirstLogin: response.data.isFirstLogin
              }

              // Guarda los datos en localStorage como en la implementación original
              localStorage.setItem('user', JSON.stringify(userData))

              set({
                isLogin: true,
                userData: userData
              })

              return {
                success: true,
                message: 'Inicio de sesión exitoso',
                role: userData.role,
                isFirstLogin: userData.isFirstLogin
              }
            } else {
              return { success: false, message: 'Error en el servidor' }
            }
          } catch (error) {
            if (error.response) {
              if (error.response.status === 401) {
                return {
                  success: false,
                  message: 'Usuario o contraseña incorrectos'
                }
              } else {
                return { success: false, message: error.response.data?.message || 'Error del servidor' }
              }
            } else if (error.request) {
              return {
                success: false,
                message: 'No se recibió respuesta del servidor'
              }
            } else {
              return {
                success: false,
                message: 'Hubo un error en la configuración de la solicitud.'
              }
            }
          }
        },

        logout: () => {
          // Elimina los datos de localStorage como en la implementación original
          localStorage.removeItem('user')

          set({
            isLogin: false,
            userData: null
          })
        },

        isAuthenticated: () => {
          return get().isLogin
        }
      }),
      {
        name: 'auth-storage', // Nombre en el almacenamiento persistente (Zustand)
        getStorage: () => localStorage, // Asegura el uso de localStorage
        serialize: state => JSON.stringify(state), // Serializa el estado para guardarlo en localStorage
        deserialize: str => JSON.parse(str) // Deserializa el estado al cargarlo
      }
    )
  )
)

export default useAuthStore
