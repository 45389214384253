import axios from 'axios'
import { API_URL } from '../constants/api'
const API_ATHLETE_URL = `${API_URL}/athlete`

export const getAllAthletes = async () => {
  /* Obtener atletas */

  try {
    const user = JSON.parse(localStorage.getItem('user'))
    const response = await axios.get(API_ATHLETE_URL, {
      headers: {
        Authorization: `Bearer ${user.access_token}`
      }
    })
    return { success: true, data: response.data }
  } catch (error) {
    return { success: false, message: 'Error al obtener los atletas' }
  }
} /* Obtener atletas */

export const getDataAthlete = async athleteId => {
  /* Obtener datos de un atleta */
  try {
    const user = JSON.parse(localStorage.getItem('user'))
    const response = await axios.get(`${API_ATHLETE_URL}/${athleteId}/cleaning_weekly`, {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
        'Content-Type': 'application/json'
      }
    })
    return { success: true, data: response.data }
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || 'Error al obtener los datos del atleta'
    }
  }
} /* Obtener datos de un atleta */

export const createAthlete = async athleteData => {
  /* Crear atleta */

  try {
    const user = JSON.parse(localStorage.getItem('user'))
    const formData = new FormData()

    // Agregar todos los campos de texto
    for (const key in athleteData) {
      if (key !== 'profileImage') {
        formData.append(key, athleteData[key])
      }
    }

    // Agregar la imagen si existe
    if (athleteData.profileImage) {
      formData.append('profileImage', athleteData.profileImage, athleteData.profileImage.name)
    }
    const response = await axios.post(API_ATHLETE_URL, formData, {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    return { success: true, data: response.data }
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || 'Error al crear el atleta'
    }
  }
} /* Crear atleta */

export const deleteAthlete = async athleteId => {
  /* Eliminar atleta */
  try {
    const user = JSON.parse(localStorage.getItem('user'))
    await axios.delete(`${API_ATHLETE_URL}/${athleteId}`, {
      headers: {
        Authorization: `Bearer ${user.access_token}`
      }
    })
    return { success: true, message: 'Atleta eliminado con éxito' }
  } catch (error) {
    console.error('Error al eliminar el atleta:', error)
    return {
      success: false,
      message: error.response?.data?.message || 'Error al eliminar el atleta'
    }
  }
} /* Eliminar atleta */

export const toggleAthleteStatus = async athleteId => {
  /* Inhabilitar atleta */
  try {
    const user = JSON.parse(localStorage.getItem('user'))
    const response = await axios.put(
      `${API_ATHLETE_URL}/${athleteId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${user.access_token}`
        }
      }
    )
    return { success: true, data: response.data }
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || 'Error al cambiar el estado del atleta'
    }
  }
} /* Inhabilitar atleta */

export const updateAthlete = async (athleteId, athleteData) => {
  /* Actualizar atleta */
  try {
    const user = JSON.parse(localStorage.getItem('user'))
    const formData = new FormData()
    for (let key in athleteData) {
      if (key === 'birthday' && athleteData[key]) {
        formData.append(key, new Date(athleteData[key]).toISOString())
      } else if (key === 'profileImage' && athleteData[key]) {
        formData.append(key, athleteData[key], athleteData[key].name)
      } else {
        formData.append(key, athleteData[key])
      }
    }
    const response = await axios.put(`${API_ATHLETE_URL}/${athleteId}/update`, formData, {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    return { success: true, data: response.data }
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || 'Error al actualizar el atleta'
    }
  }
} /* Actualizar atleta */
