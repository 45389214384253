// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormInput_formGroup__rP\\+iz {
  margin-bottom: 1rem;
}

.FormInput_label__5\\+xFW {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.FormInput_input__LSlh6 {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.FormInput_input__LSlh6:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.FormInput_feedback__rBfS6 {
  color: #dc3545;
  margin-top: 0.25rem;
  font-size: 0.875em;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/FormInput/FormInput.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,gDAAgD;AAClD;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".formGroup {\n  margin-bottom: 1rem;\n}\n\n.label {\n  font-weight: bold;\n  margin-bottom: 0.5rem;\n}\n\n.input {\n  width: 100%;\n  padding: 0.5rem;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\n.input:focus {\n  outline: none;\n  border-color: #007bff;\n  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);\n}\n\n.feedback {\n  color: #dc3545;\n  margin-top: 0.25rem;\n  font-size: 0.875em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formGroup": `FormInput_formGroup__rP+iz`,
	"label": `FormInput_label__5+xFW`,
	"input": `FormInput_input__LSlh6`,
	"feedback": `FormInput_feedback__rBfS6`
};
export default ___CSS_LOADER_EXPORT___;
