import React from 'react'
import FormInput from '../../../common/FormInput'

const BirthdayInput = ({ value, onChange, error }) => (
  <FormInput
    id='birthday'
    label='Fecha de Nacimiento'
    type='date'
    value={value}
    onChange={onChange}
    error={error}
  />
)

export default BirthdayInput
