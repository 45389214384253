import React from 'react'
import Chart from 'react-apexcharts'
import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'

export const BarChart = ({ data, categories, title }) => {
  const options = {
    chart: {
      id: 'bar-chart',
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false
      }
    },
    xaxis: {
      type: 'category',
      categories: categories,
      labels: {
        style: {
          colors: '#CFCFCFFF'
        }
      }
    },
    legend: {
      position: 'right',
      offsetY: 40,
      labels: {
        colors: '#CFCFCFFF'
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#CFCFCFFF'
        }
      }
    },
    title: {
      text: title
    },
    colors: ['#11771FFF', '#C2BE00FF', '#B93434FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        dataLabels: {
          hideOverflowingLabels: true,
          total: {
            enabled: true,
            formatter: function (total) {
              return total.toFixed(2)
            },
            style: {
              fontSize: '13px',
              fontWeight: 900,
              color: '#CFCFCFFF'
            }
          }
        }
      }
    }
  }
  const series = data
  return (
    <Chart
      options={options}
      series={series}
      type='bar'
      height={350}
    />
  )
}

BarChart.propTypes = {
  data: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
}

// export const LineChart = ({ data, categories, title }) => {
//   const options = {
//     chart: {
//       id: 'line-chart'
//     },
//     xaxis: {
//       categories: categories
//     },
//     title: {
//       text: title
//     }
//   }

//   const series = [
//     {
//       name: 'Recovery Index',
//       data: data
//     }
//   ]

//   return (
//     <ReactApexChart
//       options={options}
//       series={series}
//       type='line'
//       height={350}
//     />
//   )
// }

// LineChart.propTypes = {
//   data: PropTypes.array.isRequired,
//   categories: PropTypes.array.isRequired,
//   title: PropTypes.string.isRequired
// }

export const ScatterChart = ({ data, title }) => {
  const options = {
    chart: {
      id: 'line-chart',
      toolbar: {
        show: false
      },
      height: 200,
      type: 'line'
    },
    stroke: {
      curve: 'smooth'
    },
    legend: {
      labels: {
        colors: '#CFCFCFFF'
      }
    },
    fill: {
      type: 'solid'
    },
    markers: {
      size: [4, 0]
    },
    tooltip: {
      shared: false,
      intersect: true
    },
    xaxis: {
      type: 'numeric',
      labels: {
        style: {
          colors: '#CFCFCFFF'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#CFCFCFFF'
        }
      }
    },
    title: {
      text: title
    }
  }

  const series = data || []
  return (
    <ReactApexChart
      options={options}
      series={series}
      height={350}
      type='line'
    />
  )
}

ScatterChart.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
}

export const SemiCircleGauge = ({ value, title, subTittle }) => {
  const options = {
    chart: {
      height: 280,
      type: 'radialBar'
    },

    series: [value],
    colors: ['#DF1919FF'],
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          // margin: 5,
          size: '65%',
          background: 'transparent',
          image: undefined
        },
        track: {
          strokeWidth: '97%',
          margin: 5,
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15
          }
        },
        dataLabels: {
          name: {
            offsetY: -10,
            color: '#fff',
            fontSize: '18px'
          },
          value: {
            color: '#1FFF2EFF',
            fontSize: '40px',
            show: true
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        shadeIntensity: 0.1,
        type: 'horizontal',
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        gradientToColors: ['#20E647'],
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'round'
    },
    labels: [title]
  }

  const series = [value]
  return (
    <div className='flex flex-col justify-center items-center text-center'>
      <Chart
        options={options}
        series={series}
        type='radialBar'
        height={500}
      />
      <h3 className='text-white font-semibold text-lg mx-2'>{subTittle}</h3>
    </div>
  )
}

SemiCircleGauge.propTypes = {
  value: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
}
