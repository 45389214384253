import axios from 'axios'
import { API_URL } from '../constants/api'

// URL de la API de login
const API_LOGIN_URL = `${API_URL}/user`

export const changePassword = async newPassword => {
  try {
    const user = JSON.parse(localStorage.getItem('user'))
    const response = await axios.post(
      `${API_LOGIN_URL}/change_password`,
      { new_password: newPassword, isFirstLogin: false },
      { headers: { Authorization: `Bearer ${user.access_token}` } }
    )

    if (response.data && response.data.success) {
      user['info_user']['user']['isFirstLogin'] = false
      const updatedUser = { ...user, isFirstLogin: false }
      localStorage.setItem('user', JSON.stringify(updatedUser))
      return { success: true, message: 'Contraseña cambiada con éxito' }
    } else {
      return { success: false, message: 'Error al cambiar la contraseña' }
    }
  } catch (error) {
    console.error('Error al cambiar la contraseña:', error)
    return { success: false, message: 'Error al cambiar la contraseña' }
  }
}
