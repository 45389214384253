import React from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import PropTypes from 'prop-types'

const SingleValueCard = ({ title, value, description }) => {
  return (
    <Card className='flex flex-col bg-[#083A59] rounded-lg py-14'>
      <CardBody className='flex flex-col gap-4 justify-center items-center text-center text-white'>
        <div className='flex flex-col items-center text-[#49BFBF] text-6xl font-bold'>{value}</div>
        <CardTitle
          className='text-lg font-semibold px-10'
          tag='h5'
          title={description}
        >
          {title}
        </CardTitle>
      </CardBody>
    </Card>
  )
}

SingleValueCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default SingleValueCard
