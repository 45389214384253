// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'single-value semi-circle'
    'scatter1 scatter2'
    'bar1 bar2';
  gap: 20px;
  padding: 20px;
}

.single-value-card {
  grid-area: single-value;
}

.semi-circle-gauge-card {
  grid-area: semi-circle;
}

.chart-card.scatter1 {
  grid-area: scatter1;
}

.chart-card.scatter2 {
  grid-area: scatter2;
}

.chart-card.bar1 {
  grid-area: bar1;
}

.chart-card.bar2 {
  grid-area: bar2;
}

/* Estilos adicionales para asegurar que los componentes ocupen todo el espacio disponible */
.single-value-card,
.semi-circle-gauge-card,
.chart-card {
  width: 100%;
  height: 100%;
  min-height: 300px; /* Ajusta según sea necesario */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chart-card > div {
  width: 100%;
  height: 100%;
}

@media (max-width: 1000px) {
  .home {
    grid-template-columns: 1fr;
    grid-template-areas:
      'single-value'
      'semi-circle'
      'scatter1'
      'scatter2'
      'bar1'
      'bar2';
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/Home.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,kCAAkC;EAClC;;;eAGa;EACb,SAAS;EACT,aAAa;AACf;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA,4FAA4F;AAC5F;;;EAGE,WAAW;EACX,YAAY;EACZ,iBAAiB,EAAE,+BAA+B;EAClD,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,0BAA0B;IAC1B;;;;;;YAMQ;EACV;AACF","sourcesContent":[".home {\n  display: grid;\n  grid-template-columns: 1fr 2fr;\n  grid-template-rows: auto auto auto;\n  grid-template-areas:\n    'single-value semi-circle'\n    'scatter1 scatter2'\n    'bar1 bar2';\n  gap: 20px;\n  padding: 20px;\n}\n\n.single-value-card {\n  grid-area: single-value;\n}\n\n.semi-circle-gauge-card {\n  grid-area: semi-circle;\n}\n\n.chart-card.scatter1 {\n  grid-area: scatter1;\n}\n\n.chart-card.scatter2 {\n  grid-area: scatter2;\n}\n\n.chart-card.bar1 {\n  grid-area: bar1;\n}\n\n.chart-card.bar2 {\n  grid-area: bar2;\n}\n\n/* Estilos adicionales para asegurar que los componentes ocupen todo el espacio disponible */\n.single-value-card,\n.semi-circle-gauge-card,\n.chart-card {\n  width: 100%;\n  height: 100%;\n  min-height: 300px; /* Ajusta según sea necesario */\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.chart-card > div {\n  width: 100%;\n  height: 100%;\n}\n\n@media (max-width: 1000px) {\n  .home {\n    grid-template-columns: 1fr;\n    grid-template-areas:\n      'single-value'\n      'semi-circle'\n      'scatter1'\n      'scatter2'\n      'bar1'\n      'bar2';\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
