// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminDashboard_container__ORxiT {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.AdminDashboard_header__BHJ0L {
  margin-bottom: 2rem;
}

.AdminDashboard_title__A4x\\+0 {
  margin-bottom: 1rem;
}

.AdminDashboard_addButton__fw0QN {
  margin-bottom: 1rem;
}

.AdminDashboard_loading__mYOs9 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
}

.AdminDashboard_error__dQQeR {
  color: red;
  text-align: center;
  margin-top: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/admin/AdminDashboard.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".container {\n  padding-top: 2rem;\n  padding-bottom: 2rem;\n}\n\n.header {\n  margin-bottom: 2rem;\n}\n\n.title {\n  margin-bottom: 1rem;\n}\n\n.addButton {\n  margin-bottom: 1rem;\n}\n\n.loading {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  font-size: 1.5rem;\n}\n\n.error {\n  color: red;\n  text-align: center;\n  margin-top: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AdminDashboard_container__ORxiT`,
	"header": `AdminDashboard_header__BHJ0L`,
	"title": `AdminDashboard_title__A4x+0`,
	"addButton": `AdminDashboard_addButton__fw0QN`,
	"loading": `AdminDashboard_loading__mYOs9`,
	"error": `AdminDashboard_error__dQQeR`
};
export default ___CSS_LOADER_EXPORT___;
