// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminForm_form__zkP4U {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.AdminForm_formFooter__DUybN {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/admin/AdminForm/AdminForm/AdminForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".form {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.formFooter {\n  display: flex;\n  justify-content: flex-end;\n  gap: 0.5rem;\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `AdminForm_form__zkP4U`,
	"formFooter": `AdminForm_formFooter__DUybN`
};
export default ___CSS_LOADER_EXPORT___;
