import React, { useState } from 'react'
import { Navbar, Nav, NavItem, Collapse, NavbarToggler, Container } from 'reactstrap'
import { NavLink as RRNavLink } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes'
import styles from './Navbar.module.css'
import MenuComponent from '../../menu/MenuComponent'

const AppNavbar = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  console.log(user)

  return (
    <Navbar
      color='dark'
      dark
      expand='md'
      className={styles.navbar}
    >
      <img
        className='w-20 h-20'
        src='/icons/logo-burs.svg'
        alt='BURS'
      />
      <Container fluid>
        <div className={styles.navbarBrand}>
          <span className={styles.userName}>{user.name}</span>
        </div>

        <NavbarToggler onClick={toggle} />

        <Collapse
          isOpen={isOpen}
          navbar
          className={styles.navbarCollapse}
        >
          <Nav
            className={styles.mainNav}
            navbar
          >
            {user.role === 'admin' && (
              <>
                <NavItem>
                  {' '}
                  {/* Opción de Administradores  */}
                  <RRNavLink
                    to={ROUTES.ADMIN_DASHBOARD}
                    className={({ isActive }) => (isActive ? `${styles.navLink} ${styles.active}` : styles.navLink)}
                  >
                    Administradores
                  </RRNavLink>
                </NavItem>{' '}
                {/* Opción de Administradores  */}
                <NavItem>
                  {' '}
                  {/* Opción de Atletas  */}
                  <RRNavLink
                    to={ROUTES.ATLETA_DASHBOARD}
                    className={({ isActive }) => (isActive ? `${styles.navLink} ${styles.active}` : styles.navLink)}
                  >
                    Atletas
                  </RRNavLink>
                </NavItem>{' '}
                {/* Opción de Administradores  */}
              </>
            )}
            <NavItem>
              <RRNavLink
                to={ROUTES.HOME}
                className={styles.navLink}
                activeClassName={styles.active}
              >
                Inicio
              </RRNavLink>
            </NavItem>
          </Nav>
          <MenuComponent />
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default AppNavbar
