import React, { useState } from 'react'
import { Table, Button, Card, CardBody, CardTitle } from 'reactstrap'
import { FaEye, FaEdit, FaTrash, FaBan } from 'react-icons/fa'
import AthleteDetails from '../AthleteDetails/AthleteDetails'
import styles from './AthleteList.module.css'

const AthleteList = ({ athletes, onDelete, onToggleStatus, onEdit }) => {
  const [selectedAthlete, setSelectedAthlete] = useState(null)
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)
  const isMobile = window.innerWidth <= 768

  const toggleDetailsModal = () => setDetailsModalOpen(!detailsModalOpen)

  const handleViewDetails = athlete => {
    setSelectedAthlete(athlete)
    toggleDetailsModal()
  }

  const renderAthleteCard = athlete => (
    <Card
      key={athlete.pk_idAthlete}
      className={styles.card}
    >
      <CardBody>
        <img
          src={athlete.user.urlImg || 'https://via.placeholder.com/50'}
          alt='Profile'
          className={styles.cardImage}
        />
        <CardTitle tag='h5'>{`${athlete.user.name} ${athlete.user.lastName}`}</CardTitle>
        <p>ID: {athlete.pk_idAthlete}</p>
        <p className={athlete.user.isActive ? styles.activeStatus : styles.inactiveStatus}>
          {athlete.user.isActive ? 'Activa' : 'Inactiva'}
        </p>
        <div className={styles.cardActions}>
          <Button
            color='primary'
            onClick={() => handleViewDetails(athlete)}
            title='Ver'
          >
            <FaEye />
          </Button>
          <Button
            color='info'
            onClick={() => onEdit(athlete)}
            title='Editar'
          >
            <FaEdit />
          </Button>
          <Button
            color='warning'
            onClick={() => onToggleStatus(athlete.pk_idAthlete)}
            title={athlete.user.isActive ? 'Desactivar' : 'Activar'}
          >
            <FaBan />
          </Button>
          <Button
            color='danger'
            onClick={() => onDelete(athlete.pk_idAthlete)}
            title='Eliminar'
          >
            <FaTrash />
          </Button>
        </div>
      </CardBody>
    </Card>
  )

  const renderAthleteTable = () => (
    <Table
      striped
      className={styles.athleteTable}
    >
      <thead>
        <tr>
          <th>ID</th>
          <th>Imagen</th>
          <th>Nombre Completo</th>
          <th>Cuenta Activa</th>
          <th>Opciones</th>
        </tr>
      </thead>
      <tbody>
        {athletes.map(athlete => (
          <tr key={athlete.pk_idAthlete}>
            <td>{athlete.pk_idAthlete}</td>
            <td>
              <img
                src={athlete.user.urlImg || 'https://via.placeholder.com/50'}
                alt='Profile'
                className={styles.profileImage}
              />
            </td>
            <td>{`${athlete.user.name} ${athlete.user.lastName}`}</td>
            <td>
              <span className={athlete.user.isActive ? styles.activeStatus : styles.inactiveStatus}>
                {athlete.user.isActive ? 'Activa' : 'Inactiva'}
              </span>
            </td>
            <td>
              <Button
                color='primary'
                onClick={() => handleViewDetails(athlete)}
                title='Ver'
              >
                <FaEye />
              </Button>{' '}
              <Button
                color='info'
                onClick={() => onEdit(athlete)}
                title='Editar'
              >
                <FaEdit />
              </Button>{' '}
              <Button
                color='warning'
                onClick={() => onToggleStatus(athlete.pk_idAthlete)}
                title={athlete.user.isActive ? 'Desactivar' : 'Activar'}
              >
                <FaBan />
              </Button>{' '}
              <Button
                color='danger'
                onClick={() => onDelete(athlete.pk_idAthlete)}
                title='Eliminar'
              >
                <FaTrash />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )

  return (
    <div>
      {isMobile ? <div className={styles.cardContainer}>{athletes.map(renderAthleteCard)}</div> : renderAthleteTable()}
      {selectedAthlete && (
        <AthleteDetails
          athlete={selectedAthlete}
          isOpen={detailsModalOpen}
          toggle={toggleDetailsModal}
        />
      )}
    </div>
  )
}

export default AthleteList
