// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar_navbar__EZxko {
  padding: 0.5rem 1rem;
}

.Navbar_navbarBrand__KL0At {
  display: flex;
  align-items: center;
  color: white;
}

.Navbar_userName__jF\\+0m {
  color: white;
  font-weight: bold;
  margin-right: 15px;
}

.Navbar_navLink__mMfHQ {
  color: rgba(255, 255, 255, 0.5);
  padding: 0.5rem 1rem;
  text-decoration: none;
}

.Navbar_navLink__mMfHQ:hover {
  color: rgba(255, 255, 255, 0.75);
}

.Navbar_active__tkO50 {
  color: #fff;
  font-weight: bold;
}

.Navbar_navbarCollapse__R7SaN {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.Navbar_mainNav__jtWZh {
  display: flex;
  margin-right: 15px;
}

.Navbar_logoutButton__K-m1U {
  white-space: nowrap;
}

@media (max-width: 768px) {
  .Navbar_navbarCollapse__R7SaN {
    flex-direction: column;
    align-items: flex-start;
  }

  .Navbar_mainNav__jtWZh {
    flex-direction: column;
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .Navbar_logoutButton__K-m1U {
    align-self: flex-start;
    margin-top: 10px;
  }

  .Navbar_navbarBrand__KL0At {
    margin-bottom: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Navbar/Navbar.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,sBAAsB;IACtB,uBAAuB;EACzB;;EAEA;IACE,sBAAsB;IACtB,WAAW;IACX,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".navbar {\n  padding: 0.5rem 1rem;\n}\n\n.navbarBrand {\n  display: flex;\n  align-items: center;\n  color: white;\n}\n\n.userName {\n  color: white;\n  font-weight: bold;\n  margin-right: 15px;\n}\n\n.navLink {\n  color: rgba(255, 255, 255, 0.5);\n  padding: 0.5rem 1rem;\n  text-decoration: none;\n}\n\n.navLink:hover {\n  color: rgba(255, 255, 255, 0.75);\n}\n\n.active {\n  color: #fff;\n  font-weight: bold;\n}\n\n.navbarCollapse {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  width: 100%;\n}\n\n.mainNav {\n  display: flex;\n  margin-right: 15px;\n}\n\n.logoutButton {\n  white-space: nowrap;\n}\n\n@media (max-width: 768px) {\n  .navbarCollapse {\n    flex-direction: column;\n    align-items: flex-start;\n  }\n\n  .mainNav {\n    flex-direction: column;\n    width: 100%;\n    margin-right: 0;\n    margin-bottom: 10px;\n  }\n\n  .logoutButton {\n    align-self: flex-start;\n    margin-top: 10px;\n  }\n\n  .navbarBrand {\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": `Navbar_navbar__EZxko`,
	"navbarBrand": `Navbar_navbarBrand__KL0At`,
	"userName": `Navbar_userName__jF+0m`,
	"navLink": `Navbar_navLink__mMfHQ`,
	"active": `Navbar_active__tkO50`,
	"navbarCollapse": `Navbar_navbarCollapse__R7SaN`,
	"mainNav": `Navbar_mainNav__jtWZh`,
	"logoutButton": `Navbar_logoutButton__K-m1U`
};
export default ___CSS_LOADER_EXPORT___;
