import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { changePassword } from '../../../services/userService'
import { toast } from 'react-toastify'

const ChangePasswordModal = ({ isOpen, toggle, onPasswordChanged }) => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleSubmit = async e => {
    e.preventDefault()
    if (newPassword !== confirmPassword) {
      toast.error('Las contraseñas no coinciden')
      return
    }

    const { success, message } = await changePassword(newPassword)
    if (success) {
      toast.success(message)
      onPasswordChanged()
    } else {
      toast.error(message)
    }
  } // handleSubmit

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop='static'
      keyboard={false}
    >
      <ModalHeader>Cambiar Contraseña</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          {' '}
          {/* Formulario cambio de contraseña */}
          <FormGroup>
            {' '}
            {/* Ingresar nueva contraseña */}
            <Label for='newPassword'>Nueva Contraseña</Label>
            <Input
              type='password'
              name='newPassword'
              id='newPassword'
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              required
            />
          </FormGroup>{' '}
          {/* Ingresar nueva contraseña */}
          <FormGroup>
            {' '}
            {/* Confirmar contraseña */}
            <Label for='confirmPassword'>Confirmar Contraseña</Label>
            <Input
              type='password'
              name='confirmPassword'
              id='confirmPassword'
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              required
            />
          </FormGroup>{' '}
          {/* Confirmar contraseña */}
          <Button
            color='primary'
            type='submit'
          >
            Cambiar Contraseña
          </Button>
        </Form>{' '}
        {/* Formulario cambio de contraseña */}
      </ModalBody>
    </Modal>
  )
}

export default ChangePasswordModal
