import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Outlet, useNavigate } from 'react-router-dom'
import { ROUTES } from '../constants/routes'
import { isAuthenticated, logout } from '../services/authService'
import AppNavbar from '../components/common/Navbar/Navbar'
import ChangePasswordModal from '../components/common/ChangePassword/ChangePasswordModal'

// Va a estar en todas las páginas
const MainLayout = () => {
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate(ROUTES.LOGIN)
      return
    }

    const userData = JSON.parse(localStorage.getItem('user'))

    setUser({
      name: userData.name,
      urlImg: userData.urlImg || 'https://via.placeholder.com/40',
      isFirstLogin: userData.isFirstLogin,
      role: userData.role
    })

    if (userData.isFirstLogin) {
      setShowChangePasswordModal(true)
    }
  }, [navigate])

  const handleLogout = () => {
    logout()
    navigate(ROUTES.LOGIN)
  }

  const handlePasswordChanged = () => {
    setShowChangePasswordModal(false)
    setUser(prevUser => ({ ...prevUser, isFirstLogin: false }))
  }

  if (!user) return null

  return (
    <div>
      <AppNavbar
        user={user}
        onLogout={handleLogout}
      />
      <Container className='mt-4'>
        <Row>
          <Col>
            <Outlet />
          </Col>
        </Row>
      </Container>

      <ChangePasswordModal
        isOpen={showChangePasswordModal}
        toggle={() => setShowChangePasswordModal(!showChangePasswordModal)}
        onPasswordChanged={handlePasswordChanged}
      />
    </div>
  )
}

export default MainLayout
