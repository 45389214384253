// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  background-color: gray;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 100px;
  height: 100px;
  align-self: center;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .login-form {
    width: 80%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/login/Login.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,UAAU;EACV,gBAAgB;EAChB,wCAAwC;AAC1C;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".login-container {\n  background-color: gray;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.login-form {\n  background-color: white;\n  padding: 20px;\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  width: 90%;\n  max-width: 400px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.logo {\n  width: 100px;\n  height: 100px;\n  align-self: center;\n  margin-bottom: 20px;\n}\n\n@media (max-width: 768px) {\n  .login-form {\n    width: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
