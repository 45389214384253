import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import Modal from '../../../common/Modal'
import ProfileImageUploader from '../ProfileImageUploader'
import EmailInput from '../EmailInput'
import NameInput from '../NameInput'
import PhoneInput from '../PhoneInput'
import BirthdayInput from '../BirthdayInput'
import styles from './AthleteForm.module.css'

const AthleteForm = ({ isOpen, toggle, onSubmit, athleteToEdit }) => {
  const [form, setForm] = useState({
    email: '',
    name: '',
    lastName: '',
    phone: '',
    birthday: '',
    profileImage: null
  })

  useEffect(() => {
    if (athleteToEdit) {
      setForm({
        email: athleteToEdit.user.email || '',
        name: athleteToEdit.user.name || '',
        lastName: athleteToEdit.user.lastName || '',
        phone: athleteToEdit.user.phone || '',
        birthday: athleteToEdit.user.birthday || '',
        profileImage: null
      })
    } else {
      setForm({
        email: '',
        name: '',
        lastName: '',
        phone: '',
        birthday: '',
        profileImage: null
      })
    }
  }, [athleteToEdit])
  const [errors, setErrors] = useState({})

  const handleChange = (name, value) => {
    setForm(prevForm => ({ ...prevForm, [name]: value }))
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (validateForm()) {
      onSubmit(form, athleteToEdit ? athleteToEdit.pk_idAthlete : null)
    }
  }

  const validateForm = () => {
    let isValid = true
    let newErrors = {}

    // Validar email
    if (!form.email.trim()) {
      newErrors.email = 'El correo electrónico es requerido'
      isValid = false
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      newErrors.email = 'Correo electrónico inválido'
      isValid = false
    }

    // Validar nombre
    if (!form.name.trim()) {
      newErrors.name = 'El nombre es requerido'
      isValid = false
    }

    // Validar apellido
    if (!form.lastName.trim()) {
      newErrors.lastName = 'El apellido es requerido'
      isValid = false
    }

    // Validar teléfono
    if (!form.phone.trim()) {
      newErrors.phone = 'El teléfono es requerido'
      isValid = false
    } else if (!/^\d{10}$/.test(form.phone)) {
      newErrors.phone = 'El teléfono debe tener 10 dígitos'
      isValid = false
    }

    // Validar fecha de nacimiento
    if (!form.birthday) {
      newErrors.birthday = 'La fecha de nacimiento es requerida'
      isValid = false
    } else {
      const birthDate = new Date(form.birthday)
      const today = new Date()
      const minDate = new Date(today.getFullYear() - 5, today.getMonth(), today.getDate())
      if (birthDate > minDate) {
        newErrors.birthday = 'Debe ser mayor de 5 años'
        isValid = false
      }
    }

    setErrors(newErrors)
    return isValid
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title={athleteToEdit ? 'Editar atleta' : 'Nuevo atleta'}
    >
      <form
        onSubmit={handleSubmit}
        className={styles.form}
      >
        <ProfileImageUploader
          image={form.profileImage}
          onChange={file => handleChange('profileImage', file)}
        />
        <EmailInput
          value={form.email}
          onChange={value => handleChange('email', value)}
          error={errors.email}
        />
        <NameInput
          name={form.name}
          lastName={form.lastName}
          onNameChange={value => handleChange('name', value)}
          onLastNameChange={value => handleChange('lastName', value)}
          errors={errors}
        />
        <PhoneInput
          value={form.phone}
          onChange={value => handleChange('phone', value)}
          error={errors.phone}
        />
        <BirthdayInput
          value={form.birthday}
          onChange={value => handleChange('birthday', value)}
          error={errors.birthday}
        />
        <div className={styles.formFooter}>
          <Button
            color='primary'
            type='submit'
          >
            {athleteToEdit ? 'Actualizar' : 'Guardar'}
          </Button>
          <Button
            color='secondary'
            onClick={toggle}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default AthleteForm
