import React, { useState } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import AthleteList from '../../../components/athlete/AthleteList'
import useAthleteDashboard from '../../../hooks/useAthleteDashboard'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styles from './AthleteDashboard.module.css'
import AthleteForm from '../../../components/athlete/AthleteForm/AthleteForm'

const AthleteDashboard = () => {
  const { athletes, loading, error, handleDelete, handleToggleStatus, handleSubmit, handleUpdate } = useAthleteDashboard()

  const [modal, setModal] = useState(false)
  const [athleteToEdit, setAthleteToEdit] = useState(null)

  const toggleModal = () => {
    setModal(!modal)
    if (modal) {
      setAthleteToEdit(null)
    }
  }

  const handleEdit = athlete => {
    setAthleteToEdit(athlete)
    setModal(true)
  }

  if (loading) return <div className={styles.loading}>Cargando...</div>
  if (error) return <div className={styles.error}>Error: {error}</div>

  return (
    <Container>
      <Row className='mb-3'>
        <Col>
          <h1 className={styles.title}>Dashboard de atletas</h1>
          <Button
            color='primary'
            onClick={toggleModal}
            className={styles.addButton}
          >
            Agregar Atleta
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <AthleteList
            athletes={athletes}
            onDelete={handleDelete}
            onToggleStatus={handleToggleStatus}
            onEdit={handleEdit}
          />
        </Col>
      </Row>
      <AthleteForm
        isOpen={modal}
        toggle={toggleModal}
        onSubmit={(formData, athleteId) => (athleteId ? handleUpdate(athleteId, formData) : handleSubmit(formData))}
        athleteToEdit={athleteToEdit}
      />
      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </Container>
  )
}

export default AthleteDashboard
