import axios from 'axios'
import { create } from 'zustand'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const useGraphicsStore = create(set => ({
  btssData: null,
  effortBreakdownData: null,
  ChronicTLData: null,
  AcuteTLData: null,
  getEffortBreakdown: async () => {
    try {
      const response = await api.get('/graphics/effort-breakdown', {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`
        }
      })
      set({ effortBreakdownData: response.data })
      return response.data
    } catch (error) {
      console.error('Error al obtener el gráfico de esfuerzo:', error)
      throw error
    }
  },
  getBtssData: async () => {
    try {
      const response = await api.get('/prediction-last-activity', {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`
        }
      })
      set({ btssData: response.data.perceived_effort_index })
      return response.data
    } catch (error) {
      console.error('Error al obtener el gráfico de BTSS:', error)
      throw error
    }
  },
  getChronicTLData: async () => {
    try {
      const response = await api.get('/graphics/ctl', {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`
        }
      })
      set({ ChronicTLData: response.data })
      return response.data
    } catch (error) {
      console.error('Error al obtener el gráfico de CTL:', error)
      throw error
    }
  },
  getAcuteTLData: async () => {
    try {
      const response = await api.get('/graphics/recovery-watch', {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`
        }
      })
      set({ AcuteTLData: response.data })
      return response.data
    } catch (error) {
      console.error('Error al obtener el gráfico de ATL:', error)
      throw error
    }
  }
}))

export default useGraphicsStore
