// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modal__9poD- {
  max-width: 500px;
}

.Modal_modalHeader__dwsf7 {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.Modal_modalBody__sxIey {
  padding: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Modal/Modal.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,gCAAgC;AAClC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".modal {\n  max-width: 500px;\n}\n\n.modalHeader {\n  background-color: #f8f9fa;\n  border-bottom: 1px solid #dee2e6;\n}\n\n.modalBody {\n  padding: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `Modal_modal__9poD-`,
	"modalHeader": `Modal_modalHeader__dwsf7`,
	"modalBody": `Modal_modalBody__sxIey`
};
export default ___CSS_LOADER_EXPORT___;
