// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NameInput_nameInputContainer__06MH9 {
  display: flex;
  gap: 1rem;
}

.NameInput_nameInputContainer__06MH9 > * {
  flex: 1 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/athlete/AthleteForm/NameInput/NameInput.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,SAAO;AACT","sourcesContent":[".nameInputContainer {\n  display: flex;\n  gap: 1rem;\n}\n\n.nameInputContainer > * {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nameInputContainer": `NameInput_nameInputContainer__06MH9`
};
export default ___CSS_LOADER_EXPORT___;
