// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AthleteDetails_container__XC0DE {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.AthleteDetails_imageContainer__EO1dm {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
}

.AthleteDetails_profileImage__oNSKP {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.AthleteDetails_datesContainer__kaGGR {
  width: 100%;
  margin-bottom: 20px;
}

.AthleteDetails_dateInfo__JnB\\+- {
  text-align: center;
}

.AthleteDetails_dateLabel__VL8Mb {
  font-size: 14px;
  color: #666;
  margin-bottom: 0;
}

.AthleteDetails_date__nLF4f {
  font-size: 16px;
  font-weight: bold;
  margin-top: 0;
}

.AthleteDetails_fullName__nFc6z {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.AthleteDetails_detailsContainer__WrXUw {
  width: 100%;
}

.AthleteDetails_detailItem__nrwK3 {
  margin-bottom: 10px;
}

.AthleteDetails_label__2Zyjc {
  font-weight: bold;
  margin-right: 10px;
}

.AthleteDetails_value__YeoyF {
  color: #333;
}

@media (max-width: 768px) {
  .AthleteDetails_datesContainer__kaGGR > div {
    margin-bottom: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/athlete/AthleteDetails/AthleteDetails.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n}\n\n.imageContainer {\n  width: 150px;\n  height: 150px;\n  border-radius: 50%;\n  overflow: hidden;\n  margin-bottom: 20px;\n}\n\n.profileImage {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.datesContainer {\n  width: 100%;\n  margin-bottom: 20px;\n}\n\n.dateInfo {\n  text-align: center;\n}\n\n.dateLabel {\n  font-size: 14px;\n  color: #666;\n  margin-bottom: 0;\n}\n\n.date {\n  font-size: 16px;\n  font-weight: bold;\n  margin-top: 0;\n}\n\n.fullName {\n  font-size: 24px;\n  font-weight: bold;\n  margin-bottom: 20px;\n  text-align: center;\n}\n\n.detailsContainer {\n  width: 100%;\n}\n\n.detailItem {\n  margin-bottom: 10px;\n}\n\n.label {\n  font-weight: bold;\n  margin-right: 10px;\n}\n\n.value {\n  color: #333;\n}\n\n@media (max-width: 768px) {\n  .datesContainer > div {\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AthleteDetails_container__XC0DE`,
	"imageContainer": `AthleteDetails_imageContainer__EO1dm`,
	"profileImage": `AthleteDetails_profileImage__oNSKP`,
	"datesContainer": `AthleteDetails_datesContainer__kaGGR`,
	"dateInfo": `AthleteDetails_dateInfo__JnB+-`,
	"dateLabel": `AthleteDetails_dateLabel__VL8Mb`,
	"date": `AthleteDetails_date__nLF4f`,
	"fullName": `AthleteDetails_fullName__nFc6z`,
	"detailsContainer": `AthleteDetails_detailsContainer__WrXUw`,
	"detailItem": `AthleteDetails_detailItem__nrwK3`,
	"label": `AthleteDetails_label__2Zyjc`,
	"value": `AthleteDetails_value__YeoyF`
};
export default ___CSS_LOADER_EXPORT___;
