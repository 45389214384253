// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminList_adminTable__yqsls {
  margin-top: 1rem;
}

.AdminList_profileImage__6TU0N {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.AdminList_activeStatus__goD9\\+ {
  color: green;
  font-weight: bold;
}

.AdminList_inactiveStatus__oyayI {
  color: red;
  font-weight: bold;
}

.AdminList_cardContainer__1Vu9L {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.AdminList_card__\\+FrPM {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.AdminList_cardImage__pz2VU {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.AdminList_cardActions__QUki5 {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/admin/AdminList/AdminList.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".adminTable {\n  margin-top: 1rem;\n}\n\n.profileImage {\n  width: 50px;\n  height: 50px;\n  object-fit: cover;\n  border-radius: 50%;\n}\n\n.activeStatus {\n  color: green;\n  font-weight: bold;\n}\n\n.inactiveStatus {\n  color: red;\n  font-weight: bold;\n}\n\n.cardContainer {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));\n  gap: 1rem;\n  margin-top: 1rem;\n}\n\n.card {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n\n.cardImage {\n  width: 100px;\n  height: 100px;\n  object-fit: cover;\n  border-radius: 50%;\n  margin-bottom: 1rem;\n}\n\n.cardActions {\n  display: flex;\n  justify-content: center;\n  gap: 0.5rem;\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminTable": `AdminList_adminTable__yqsls`,
	"profileImage": `AdminList_profileImage__6TU0N`,
	"activeStatus": `AdminList_activeStatus__goD9+`,
	"inactiveStatus": `AdminList_inactiveStatus__oyayI`,
	"cardContainer": `AdminList_cardContainer__1Vu9L`,
	"card": `AdminList_card__+FrPM`,
	"cardImage": `AdminList_cardImage__pz2VU`,
	"cardActions": `AdminList_cardActions__QUki5`
};
export default ___CSS_LOADER_EXPORT___;
