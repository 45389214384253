// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileImageUploader_container__ivPZQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.ProfileImageUploader_imagePreview__RVVDf {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  margin-bottom: 1rem;
}

.ProfileImageUploader_previewImage__XjSz\\+ {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ProfileImageUploader_buttonGroup__f4XgH {
  display: flex;
  gap: 1rem;
}

.ProfileImageUploader_uploadButton__GXVyx {
  margin-bottom: 0;
}

.ProfileImageUploader_hiddenInput__BhJ2G {
  display: none;
}

.ProfileImageUploader_cameraContainer__nC5Bc {
  width: 100%;
  max-width: 500px;
}

.ProfileImageUploader_camera__DRwAB {
  width: 100%;
  height: auto;
}

.ProfileImageUploader_cameraButtons__3x0WX {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/athlete/AthleteForm/ProfileImageUploader/ProfileImageUploader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 1rem;\n}\n\n.imagePreview {\n  width: 150px;\n  height: 150px;\n  border-radius: 50%;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #f0f0f0;\n  margin-bottom: 1rem;\n}\n\n.previewImage {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.buttonGroup {\n  display: flex;\n  gap: 1rem;\n}\n\n.uploadButton {\n  margin-bottom: 0;\n}\n\n.hiddenInput {\n  display: none;\n}\n\n.cameraContainer {\n  width: 100%;\n  max-width: 500px;\n}\n\n.camera {\n  width: 100%;\n  height: auto;\n}\n\n.cameraButtons {\n  display: flex;\n  justify-content: center;\n  gap: 1rem;\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProfileImageUploader_container__ivPZQ`,
	"imagePreview": `ProfileImageUploader_imagePreview__RVVDf`,
	"previewImage": `ProfileImageUploader_previewImage__XjSz+`,
	"buttonGroup": `ProfileImageUploader_buttonGroup__f4XgH`,
	"uploadButton": `ProfileImageUploader_uploadButton__GXVyx`,
	"hiddenInput": `ProfileImageUploader_hiddenInput__BhJ2G`,
	"cameraContainer": `ProfileImageUploader_cameraContainer__nC5Bc`,
	"camera": `ProfileImageUploader_camera__DRwAB`,
	"cameraButtons": `ProfileImageUploader_cameraButtons__3x0WX`
};
export default ___CSS_LOADER_EXPORT___;
