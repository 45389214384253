import axios from 'axios'
import { API_URL } from '../constants/api'
const API_ADMIN_URL = `${API_URL}/admin`

export const getAllAdmins = async () => {
  /* Obtener todos los administradores */

  try {
    const user = JSON.parse(localStorage.getItem('user'))

    if (!user || !user.access_token) {
      return { success: false, message: 'No se encontró el token de acceso' }
    }

    const response = await axios.get(API_ADMIN_URL, {
      headers: {
        Authorization: `Bearer ${user.access_token}`
      }
    })

    return { success: true, data: response.data }
  } catch (error) {
    return {
      success: false,

      message: error.response?.data?.message || 'Error al obtener los administradores'
    }
  }
} /* Obtener todos los administradores */

export const createAdmin = async adminData => {
  /* Crear un nuevo administrador */

  try {
    const user = JSON.parse(localStorage.getItem('user'))

    const formData = new FormData()

    // Agregar todos los campos de texto
    for (const key in adminData) {
      if (key !== 'profileImage') {
        formData.append(key, adminData[key])
      }
    }

    // Agregar la imagen si existe
    if (adminData.profileImage) {
      formData.append('profileImage', adminData.profileImage, adminData.profileImage.name)
    }

    const response = await axios.post(API_ADMIN_URL, formData, {
      headers: {
        Authorization: `Bearer ${user.access_token}`,

        'Content-Type': 'multipart/form-data'
      }
    })

    return { success: true, data: response.data }
  } catch (error) {
    console.error('Error al crear el administrador:', error)

    return {
      success: false,

      message: error.response?.data?.message || 'Error al crear el administrador'
    }
  }
} /* Crear un nuevo administrador */

export const deleteAdmin = async adminId => {
  /* Eliminar un administrador */

  try {
    const user = JSON.parse(localStorage.getItem('user'))

    await axios.delete(`${API_ADMIN_URL}/${adminId}`, {
      headers: {
        Authorization: `Bearer ${user.access_token}`
      }
    })

    return { success: true, message: 'Administrador eliminado con éxito' }
  } catch (error) {
    return {
      success: false,

      message: error.response?.data?.message || 'Error al eliminar el administrador'
    }
  }
} /* Eliminar un administrador */

export const toggleAdminStatus = async adminId => {
  /* Cambiar estatus de administrador */

  try {
    const user = JSON.parse(localStorage.getItem('user'))

    const response = await axios.put(
      `${API_ADMIN_URL}/${adminId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${user.access_token}`
        }
      }
    )

    return { success: true, data: response.data }
  } catch (error) {
    return {
      success: false,

      message: error.response?.data?.message || 'Error al cambiar el estado del administrador'
    }
  }
} /* Cambiar estatus de administrador */

export const updateAdmin = async (adminId, adminData) => {
  /* Actualizar datos de administrador */

  try {
    const user = JSON.parse(localStorage.getItem('user'))

    const formData = new FormData()

    for (let key in adminData) {
      if (key === 'birthday' && adminData[key]) {
        const date = new Date(adminData[key])
        const formattedDate = date.toISOString().split('T')[0] // Obtiene solo la parte de la fecha

        formData.append(key, formattedDate)
      } else if (key === 'profileImage' && adminData[key]) {
        formData.append(key, adminData[key], adminData[key].name)
      } else {
        formData.append(key, adminData[key])
      }
    }

    const response = await axios.put(`${API_ADMIN_URL}/${adminId}/update`, formData, {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
        'Content-Type': 'multipart/form-data'
      }
    })

    return { success: true, data: response.data }
  } catch (error) {
    console.error('Error al actualizar el administrador:', error)

    return {
      success: false,

      message: error.response?.data?.message || 'Error al actualizar el administrador'
    }
  }
} /* Actualizar datos de administrador */
