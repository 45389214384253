// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AthleteList_athleteTable__aVVpg {
  margin-top: 1rem;
}

.AthleteList_profileImage__NF6q- {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.AthleteList_activeStatus__b8oVY {
  color: green;
  font-weight: bold;
}

.AthleteList_inactiveStatus__qpfHt {
  color: red;
  font-weight: bold;
}

.AthleteList_cardContainer__LNB54 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.AthleteList_card__n62Yw {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.AthleteList_cardImage__zAnvx {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.AthleteList_cardActions__0Mxxj {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .AthleteList_athleteTable__aVVpg {
    display: none;
  }
}

@media (min-width: 769px) {
  .AthleteList_cardContainer__LNB54 {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/athlete/AthleteList/AthleteList.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".athleteTable {\n  margin-top: 1rem;\n}\n\n.profileImage {\n  width: 50px;\n  height: 50px;\n  object-fit: cover;\n  border-radius: 50%;\n}\n\n.activeStatus {\n  color: green;\n  font-weight: bold;\n}\n\n.inactiveStatus {\n  color: red;\n  font-weight: bold;\n}\n\n.cardContainer {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));\n  gap: 1rem;\n  margin-top: 1rem;\n}\n\n.card {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n\n.cardImage {\n  width: 100px;\n  height: 100px;\n  object-fit: cover;\n  border-radius: 50%;\n  margin-bottom: 1rem;\n}\n\n.cardActions {\n  display: flex;\n  justify-content: center;\n  gap: 0.5rem;\n  margin-top: 1rem;\n}\n\n@media (max-width: 768px) {\n  .athleteTable {\n    display: none;\n  }\n}\n\n@media (min-width: 769px) {\n  .cardContainer {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"athleteTable": `AthleteList_athleteTable__aVVpg`,
	"profileImage": `AthleteList_profileImage__NF6q-`,
	"activeStatus": `AthleteList_activeStatus__b8oVY`,
	"inactiveStatus": `AthleteList_inactiveStatus__qpfHt`,
	"cardContainer": `AthleteList_cardContainer__LNB54`,
	"card": `AthleteList_card__n62Yw`,
	"cardImage": `AthleteList_cardImage__zAnvx`,
	"cardActions": `AthleteList_cardActions__0Mxxj`
};
export default ___CSS_LOADER_EXPORT___;
