import axios from 'axios'
import { API_URL } from '../constants/api'
const API_AUTH_STRAVA_URL = `${API_URL}/auth_strava`
const API_EXCHANGE_TOKEN_URL = `${API_URL}/token_strava`
const API_VERIFY_TOKEN_URL = `${API_URL}/strava/verify_token`
const API_GET_DATA_RAW = `${API_URL}/strava/raw_data`

// Función para obtener la URL de autorización de Strava
export const getStravaAuthUrl = async () => {
  try {
    const response = await axios.get(API_AUTH_STRAVA_URL, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`
      }
    })
    console.log('response: ', response)
    return response.data.auth_url
  } catch (error) {
    console.error('Error al obtener la URL de autorización de Strava:', error)
    throw error
  }
}

// Función para intercambiar el código de autorización por un token de acceso
export const exchangeStravaToken = async code => {
  try {
    const response = await axios.post(
      API_EXCHANGE_TOKEN_URL,
      { code },
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`
        }
      }
    )
    return response.data
  } catch (error) {
    console.error('Error al intercambiar el token de Strava:', error)
    throw error
  }
}

// Función para verificar si el token de Strava es válido
export const verifyStravaToken = async () => {
  try {
    console.log('Se verifica si funciona el token de strava almacenado')
    const response = await axios.get(API_VERIFY_TOKEN_URL, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`
      }
    })
    return response.data.is_valid
  } catch (error) {
    console.error('Error al verificar el token de Strava:', error)
    return false
  }
}

export const getDataStravaAtleta = async () => {
  try {
    const response = await axios.get(API_GET_DATA_RAW, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`
      }
    })
    console.log('response: ', response)
    return response.data
  } catch (error) {
    console.error('Error al obtener la información del atleta:', error)
    throw error
  }
}
