import React from 'react'
import FormInput from '../../../common/FormInput'

const EmailInput = ({ value, onChange, error }) => (
  <FormInput
    id='email'
    label='Correo Electrónico'
    type='email'
    value={value}
    onChange={onChange}
    error={error}
  />
)

export default EmailInput
