import React, { useState, useEffect, useRef } from 'react'
import { Button, FormGroup, Label, Input } from 'reactstrap'
import Webcam from 'react-webcam'
import styles from './ProfileImageUploader.module.css'

const ProfileImageUploader = ({ image, onChange }) => {
  const [imagePreview, setImagePreview] = useState(null)
  const [showCamera, setShowCamera] = useState(false)
  const webcamRef = useRef(null)

  useEffect(() => {
    if (image instanceof File) {
      const reader = new FileReader()
      reader.onloadend = () => setImagePreview(reader.result)
      reader.readAsDataURL(image)
    } else {
      setImagePreview(image)
    }
  }, [image])

  const handleFileChange = event => {
    const file = event.target.files[0]
    if (file) {
      onChange(file)
    }
  }

  const handleCameraCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot()
    if (imageSrc) {
      fetch(imageSrc)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'camera_capture.jpg', { type: 'image/jpeg' })
          onChange(file)
          setShowCamera(false)
        })
    }
  }

  return (
    <div className={styles.container}>
      {!showCamera ? (
        <>
          <div className={styles.imagePreview}>
            {imagePreview ? (
              <img
                src={imagePreview}
                alt='Vista previa'
                className={styles.previewImage}
              />
            ) : (
              <span>Sin imagen</span>
            )}
          </div>
          <FormGroup className={styles.buttonGroup}>
            <Label
              for='profileImage'
              className={`btn btn-outline-primary ${styles.uploadButton}`}
            >
              {imagePreview ? 'Cambiar imagen' : 'Seleccionar imagen'}
            </Label>
            <Input
              id='profileImage'
              name='profileImage'
              type='file'
              onChange={handleFileChange}
              accept='image/*'
              className={styles.hiddenInput}
            />
            <Button
              color='secondary'
              onClick={() => setShowCamera(true)}
            >
              Usar cámara
            </Button>
          </FormGroup>
        </>
      ) : (
        <div className={styles.cameraContainer}>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat='image/jpeg'
            className={styles.camera}
          />
          <div className={styles.cameraButtons}>
            <Button
              color='primary'
              onClick={handleCameraCapture}
            >
              Capturar
            </Button>
            <Button
              color='secondary'
              onClick={() => setShowCamera(false)}
            >
              Cancelar
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProfileImageUploader
