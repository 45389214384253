import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { BarChart, ScatterChart, SemiCircleGauge } from '../../components/graphics/graphics'
import ChartCard from '../../components/graphics/ChartCard'
import SingleValueCard from '../../components/graphics/SingleValueCard'
import { exchangeStravaToken, verifyStravaToken, getStravaAuthUrl } from '../../services/stravaService'
import './Home.css'
import { ROUTES } from '../../constants/routes'
import { getDataAthlete } from '../../services/athleteService'
import useGraphicsStore from '../../stores/useGraphicsStore'
import ModalComponent from '../../components/modals/ModalComponent'

const Home = () => {
  const [isStravaConnected, setIsStravaConnected] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation() // obtener información sobre la url actual
  const navigate = useNavigate() // navegar por las rutas de la aplicación
  const [data, setData] = useState(null)
  const { getEffortBreakdown, effortBreakdownData, getBtssData, btssData, ChronicTLData, getChronicTLData, AcuteTLData, getAcuteTLData } =
    useGraphicsStore()
  const userAuthData = JSON.parse(localStorage.getItem('user'))

  console.log(userAuthData)

  const dataAtleta = async () => {
    const userData = JSON.parse(localStorage.getItem('user'))
    try {
      setIsLoading(true)
      await getEffortBreakdown(userData.info_user.pk_idAthlete)
      await getBtssData(userData.info_user.pk_idAthlete)
      await getChronicTLData(userData.info_user.pk_idAthlete)
      await getAcuteTLData(userData.info_user.pk_idAthlete)
      const response = await getDataAthlete(userData.info_user.pk_idAthlete)
      setData(response.data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Error al obtener la información del atleta:', error)
    }
  }

  useEffect(() => {
    const checkStravaAuth = async () => {
      const userData = JSON.parse(localStorage.getItem('user'))

      if (userData?.info_user?.tokenStrava) {
        try {
          const isValid = await verifyStravaToken()
          setIsStravaConnected(isValid)
        } catch (error) {
          console.error('Error al verificar el token de Strava:', error)
          setIsStravaConnected(false)
        }
      } else {
        setIsStravaConnected(false)
      }

      setIsLoading(false)
    }

    const handleStravaCallback = async () => {
      console.log('Se verifica que se obtenga el código de la url')
      const query = new URLSearchParams(window.location.search)
      const code = query.get('code')

      if (code) {
        try {
          await exchangeStravaToken(code)
          setIsStravaConnected(true)
          navigate(ROUTES.HOME, { replace: true })
        } catch (error) {
          console.error('Error al intercambiar el token de Strava:', error)
        }
      }
    }

    checkStravaAuth()
    handleStravaCallback()
  }, [location, navigate])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (isStravaConnected) {
      dataAtleta()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStravaConnected])

  const handleConnectStrava = async () => {
    try {
      const url = await getStravaAuthUrl()
      window.location.href = url
    } catch (error) {
      console.error('Error al obtener la URL de autorización de Strava:', error)
    }
  }

  // Verificar que effortBreakdownData no sea null antes de usar .map()
  const barChartData = effortBreakdownData
    ? [
        {
          name: 'Easy',
          data: effortBreakdownData.map(item => item.Easy)
        },
        {
          name: 'Medium',
          data: effortBreakdownData.map(item => item.Medium)
        },
        {
          name: 'Hard',
          data: effortBreakdownData.map(item => item.Hard)
        }
      ]
    : []

  // se le agrega a cada elemento del array barChartCategories la palabra 'Week' y el número de la semana
  const barChartCategories = effortBreakdownData ? effortBreakdownData.map(item => `Week ${item.week}`) : []

  if (isLoading) {
    return <div>Cargando...</div>
  }

  if ((!isStravaConnected || data === null) && !isLoading) {
    return (
      <ModalComponent
        title='Welcome to BURS'
        description='Para ver tus datos de entrenamiento, primero necesitamos que conectes tu cuenta de Strava.'
        textButton='Conectar con Strava'
        action={() => {
          handleConnectStrava()
        }}
      />
    )
  }

  // se usa el ChronicTLData para obtener los datos de la grafica
  const dataLineChart = ChronicTLData
    ? ChronicTLData.map(item => {
        return { x: item.week, y: item.ctl }
      })
    : []
  const dataTendencia = ChronicTLData
    ? ChronicTLData.map(item => {
        return { x: item.week, y: item.tendencia }
      })
    : []

  const acuteTLCharData = AcuteTLData
    ? AcuteTLData.map(item => {
        return { x: item.week, y: item.acuteTL }
      })
    : []

  const acuteTLTendencia = AcuteTLData
    ? AcuteTLData.map(item => {
        return { x: item.week, y: item.tendencia }
      })
    : []

  const scatterChartAcuteTL = [
    {
      name: 'Acute Training Load',
      type: 'line',
      data: acuteTLCharData
    },
    {
      name: 'Acute TL',
      type: 'scatter',
      data: acuteTLCharData
    },
    {
      name: 'Tendencia',
      type: 'line',
      data: acuteTLTendencia
    }
  ]

  const scatterData = [
    {
      name: 'Chronical Training Load',
      type: 'line',
      data: dataLineChart
    },
    {
      name: 'CTL',
      type: 'scatter',
      data: dataLineChart
    },
    {
      name: 'Tendencia',
      type: 'line',
      data: dataTendencia
    }
  ]

  const semiCircleGaugeValue = 100
  // setea el btss con maximo 4 decimales
  const BTSS = btssData ? btssData.toFixed(2) : 0

  return (
    <div className='mt-4 grid grid-cols-4 gap-4'>
      <div className='col-span-1 h-full flex flex-col gap-4'>
        <SingleValueCard
          className='single-value-card'
          title='BURS Training Stress Score'
          value={BTSS}
          description={`This scorecard measures the overall training stress your body experiences each day.
It's a BURS-made daily stress scorecard, helping you understand how hard you're pushing yourself.

By tracking your training stress score, we can identify patterns, adjust your training intensity as needed, and ensure you're striking the right balance between pushing your limits and allowing for adequate recovery. 
`}
        />

        <ChartCard
          className='semi-circle-gauge-card'
          title='Natural Network Model (Weekly)'
        >
          <SemiCircleGauge
            value={semiCircleGaugeValue}
            title='Recovery Index'
            subTittle={`This week recovery index is ${semiCircleGaugeValue}, keep up the good work!`}
            width='100%'
            height='100%'
          />
        </ChartCard>
      </div>
      {/* agrega un hover con la descripcion de la grafica */}
      <div className='col-span-3 flex flex-col gap-4'>
        <ChartCard
          className='chart-card bar2'
          title='4 week Monthly Load (21 weeks)'
          description={`This graph shows the distribution of your training intensity across different workout zones. While there's no one-size-fits-all approach to training, the phase of your training often emphasizes either higher intensity or higher volume.

Low Intensity: This zone primarily focuses on aerobic load, improving your endurance and base fitness.

Medium Intensity: This zone, often referred to as the tempo or lactate threshold zone, helps build your aerobic capacity and tolerance to sustained effort.

High Intensity: This zone targets your anaerobic system, improving your ability to run faster and longer. However, excessive high-intensity training can lead to fatigue and injury.

The more detailed and data-driven your training, the better your overall development. By understanding your intensity distribution, we can make informed decisions about your training to optimize your performance.
`}
        >
          <BarChart
            data={barChartData}
            categories={barChartCategories}
            // title='4 week Monthly Load (21 weeks) _if'
          />
        </ChartCard>
        <ChartCard
          className='chart-card scatter1'
          title='Aerobic Training Load'
        >
          <ScatterChart
            data={scatterData}
            // categories={scatterCategories}
            // title='Aerobic Training Load'
          />
        </ChartCard>

        <ChartCard
          className='chart-card scatter2'
          title='Chronic Training Load (4 week average)'
        >
          <ScatterChart
            data={scatterChartAcuteTL}
            // title='Chronic Training Load (4 week average)'
          />
        </ChartCard>

        {/* <ChartCard
          className='chart-card bar1'
          title='Training Factor (Daily)'
        >
          <BarChart
            data={intensityDistribution}
            categories={barChartCategories}
            title='Training Factor (Daily)'
          />
        </ChartCard> */}
      </div>
    </div>
  )
}

export default Home
