import axios from 'axios'
import { API_URL } from '../constants/api'

// URL de la API de login
const API_LOGIN_URL = `${API_URL}/login`

// Función para manejar el login
export const login = async (email, password) => {
  try {
    const response = await axios.post(API_LOGIN_URL, { email, password })
    if (response.data && response.data.access_token) {
      console.log('authService | response.data: ', response.data)
      const userData = {
        access_token: response.data.access_token,
        info_user: response.data.info_user,
        role: response.data.role,
        isFirstLogin: response.data.isFirstLogin
      }

      localStorage.setItem('user', JSON.stringify(response.data))

      const resp = {
        success: true,
        message: 'Bienvenido',
        role: userData.role,
        isFirstLogin: userData.isFirstLogin
      }
      return resp
    } else {
      return { success: false, message: 'Error en el servidor' }
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return { success: false, message: 'Credenciales incorrectas' }
      } else {
        return { success: false, message: `Error del servidor` }
      }
    } else if (error.request) {
      return { success: false, message: 'No se recibió respuesta del servidor' }
    } else {
      return { success: false, message: 'Hubo un error en la configuración de la solicitud.' }
    }
  }
}

// Función para verificar si el usuario está autenticado
export const isAuthenticated = () => {
  return localStorage.getItem('user') !== null
}

// Función para cerrar sesión
export const logout = () => {
  localStorage.removeItem('user')
}
