import React, { useState } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import AdminList from '../../../components/admin/AdminList'
import AdminForm from '../../../components/admin/AdminForm'
import useAdminDashboard from '../../../hooks/useAdminDashboard'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styles from './AdminDashboard.module.css'

const AdminDashboard = () => {
  const { admins, loading, error, modal, toggleModal, handleDelete, handleToggleStatus, handleSubmit, handleUpdate } = useAdminDashboard()

  const [adminToEdit, setAdminToEdit] = useState(null)

  const handleEdit = admin => {
    setAdminToEdit(admin)
    toggleModal()
  }

  if (loading) return <div className={styles.loading}>Cargando...</div>

  if (error) return <div className={styles.error}>Error: {error}</div>

  return (
    <Container className={styles.container}>
      <Row className={styles.header}>
        <Col>
          <h1 className={styles.title}>Dashboard de administradores</h1>
          <Button
            color='primary'
            onClick={() => {
              setAdminToEdit(null)
              toggleModal()
            }}
            className={styles.addButton}
          >
            Agregar Administrador
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <AdminList
            admins={admins}
            onDelete={handleDelete}
            onToggleStatus={handleToggleStatus}
            onEdit={handleEdit}
          />
        </Col>
      </Row>
      <AdminForm
        isOpen={modal}
        toggle={toggleModal}
        onSubmit={formData => (adminToEdit ? handleUpdate(adminToEdit.pk_idAdmin, formData) : handleSubmit(formData))}
        adminToEdit={adminToEdit}
      />
      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </Container>
  )
}

export default AdminDashboard
