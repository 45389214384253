import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { forwardRef } from 'react'

// type InputWithValidationProps = {
//   label: string
//   id: string
//   name: string
//   type?: string
//   placeholder?: string
//   errorMessage?: string
//   isError?: boolean
//   icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
// }

// Usamos forwardRef para permitir que el componente acepte refs
const InputWithValidation = forwardRef(
  ({ label, id, name, type = 'text', placeholder, errorMessage, isError, icon: Icon = ExclamationCircleIcon, ...props }, ref) => {
    return (
      <div>
        <label
          htmlFor={id}
          className='block text-sm font-medium leading-6 text-gray-900'
        >
          {label}
        </label>
        <div className='relative mt-2 rounded-md shadow-sm'>
          <input
            id={id}
            name={name}
            type={type}
            placeholder={placeholder}
            aria-invalid={isError}
            aria-describedby={isError ? `${id}-error` : undefined}
            className={`block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
              isError ? 'text-black ring-red-300 focus:ring-red-500 placeholder:text-red-500' : 'ring-gray-300 focus:ring-blue-500'
            } sm:text-sm sm:leading-6`}
            ref={ref} // Pasamos el ref al input
            {...props}
          />
          {isError && (
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
              <Icon
                aria-hidden='true'
                className='h-5 w-5 text-red-500'
              />
            </div>
          )}
        </div>
        <p
          id={`${id}-error`}
          className={`mt-2 text-sm text-red-600 transition-opacity duration-300 ${isError ? 'opacity-100' : 'opacity-0'}`}
          style={{ minHeight: '1.25rem' }}
        >
          {' '}
          {isError ? errorMessage : '\u00A0'}
        </p>
      </div>
    )
  }
)

// Asignar un nombre para la depuración
InputWithValidation.displayName = 'InputWithValidation'

export default InputWithValidation
