import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { getAllAdmins, createAdmin, deleteAdmin, toggleAdminStatus, updateAdmin } from '../services/adminService'

const useAdminDashboard = () => {
  const [admins, setAdmins] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    fetchAdmins()
  }, [])

  const fetchAdmins = async () => {
    setLoading(true)
    const { success, data, message } = await getAllAdmins()
    if (success) {
      setAdmins(data)
    } else {
      setError(message)
    }
    setLoading(false)
  }

  const toggleModal = () => setModal(!modal)

  const handleUpdate = async (adminId, adminData) => {
    try {
      const result = await updateAdmin(adminId, adminData)
      if (result.success) {
        setAdmins(admins.map(admin => (admin.pk_idAdmin === adminId ? { ...admin, user: { ...admin.user, ...result.data } } : admin)))
        toast.success('Administrador actualizado con éxito')
        toggleModal()
      } else {
        toast.error(result.message)
      }
    } catch (error) {
      toast.error('Error al actualizar el administrador')
    }
  }

  const handleDelete = async adminId => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este administrador?')) {
      const { success, message } = await deleteAdmin(adminId)
      if (success) {
        toast.success(message)
        fetchAdmins()
      } else {
        toast.error(message)
      }
    }
  }

  const handleToggleStatus = async adminId => {
    const { success, message } = await toggleAdminStatus(adminId)
    if (success) {
      toast.success(`Estado del administrador actualizado`)
      fetchAdmins()
    } else {
      toast.error(message)
    }
  }

  const handleSubmit = async formData => {
    try {
      const { success, message } = await createAdmin(formData)
      if (success) {
        toast.success('Administrador creado con éxito')
        toggleModal()
        fetchAdmins()
      } else {
        toast.error(message)
      }
    } catch (error) {
      toast.error('Error al crear el administrador')
    }
  }

  return {
    admins,
    loading,
    error,
    modal,
    toggleModal,
    handleDelete,
    handleToggleStatus,
    handleSubmit,
    handleUpdate
  }
}

export default useAdminDashboard
