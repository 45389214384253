// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminDetails_container__cdeXl {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.AdminDetails_imageContainer__BM05e {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
}

.AdminDetails_profileImage__B\\+2C6 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.AdminDetails_datesContainer__zWUgl {
  width: 100%;
  margin-bottom: 20px;
}

.AdminDetails_dateInfo__m3R8x {
  text-align: center;
}

.AdminDetails_dateLabel__JJi-W {
  font-size: 14px;
  color: #666;
  margin-bottom: 0;
}

.AdminDetails_date__YT4xM {
  font-size: 16px;
  font-weight: bold;
  margin-top: 0;
}

.AdminDetails_fullName__o5iu7 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.AdminDetails_detailsContainer__BMyT\\+ {
  width: 100%;
}

.AdminDetails_detailItem__\\+SbyQ {
  margin-bottom: 10px;
}

.AdminDetails_label__L0\\+Wj {
  font-weight: bold;
  margin-right: 10px;
}

.AdminDetails_value__CFN9c {
  color: #333;
}

@media (max-width: 768px) {
  .AdminDetails_datesContainer__zWUgl > div {
    margin-bottom: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/admin/AdminDetails/AdminDetails.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n}\n\n.imageContainer {\n  width: 150px;\n  height: 150px;\n  border-radius: 50%;\n  overflow: hidden;\n  margin-bottom: 20px;\n}\n\n.profileImage {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.datesContainer {\n  width: 100%;\n  margin-bottom: 20px;\n}\n\n.dateInfo {\n  text-align: center;\n}\n\n.dateLabel {\n  font-size: 14px;\n  color: #666;\n  margin-bottom: 0;\n}\n\n.date {\n  font-size: 16px;\n  font-weight: bold;\n  margin-top: 0;\n}\n\n.fullName {\n  font-size: 24px;\n  font-weight: bold;\n  margin-bottom: 20px;\n  text-align: center;\n}\n\n.detailsContainer {\n  width: 100%;\n}\n\n.detailItem {\n  margin-bottom: 10px;\n}\n\n.label {\n  font-weight: bold;\n  margin-right: 10px;\n}\n\n.value {\n  color: #333;\n}\n\n@media (max-width: 768px) {\n  .datesContainer > div {\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AdminDetails_container__cdeXl`,
	"imageContainer": `AdminDetails_imageContainer__BM05e`,
	"profileImage": `AdminDetails_profileImage__B+2C6`,
	"datesContainer": `AdminDetails_datesContainer__zWUgl`,
	"dateInfo": `AdminDetails_dateInfo__m3R8x`,
	"dateLabel": `AdminDetails_dateLabel__JJi-W`,
	"date": `AdminDetails_date__YT4xM`,
	"fullName": `AdminDetails_fullName__o5iu7`,
	"detailsContainer": `AdminDetails_detailsContainer__BMyT+`,
	"detailItem": `AdminDetails_detailItem__+SbyQ`,
	"label": `AdminDetails_label__L0+Wj`,
	"value": `AdminDetails_value__CFN9c`
};
export default ___CSS_LOADER_EXPORT___;
