import React from 'react'
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap'
import styles from './AdminDetails.module.css'

const AdminDetails = ({ admin, isOpen, toggle }) => {
  const calculateAge = birthday => {
    if (!birthday) return 'No disponible'
    const ageDifMs = Date.now() - new Date(birthday).getTime()
    const ageDate = new Date(ageDifMs)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  const formatDate = dateString => {
    if (!dateString) return 'No disponible'
    return new Date(dateString).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size='lg'
    >
      <ModalHeader toggle={toggle}>Detalles del administrador</ModalHeader>
      <ModalBody>
        <div className={styles.container}>
          <div className={styles.imageContainer}>
            <img
              src={admin.urlImg || 'https://via.placeholder.com/150'}
              alt='Perfil'
              className={styles.profileImage}
            />
          </div>

          <Row className={styles.datesContainer}>
            <Col md={6}>
              <div className={styles.dateInfo}>
                <p className={styles.dateLabel}>Cuenta creada</p>
                <p className={styles.date}>{formatDate(admin.createAccount)}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className={styles.dateInfo}>
                <p className={styles.dateLabel}>Última sesión</p>
                <p className={styles.date}>{formatDate(admin.lastLogin)}</p>
              </div>
            </Col>
          </Row>

          <h2 className={styles.fullName}>{`${admin.name} ${admin.lastName}`}</h2>

          <Row className={styles.detailsContainer}>
            <Col md={6}>
              <div className={styles.detailItem}>
                <span className={styles.label}>Email:</span>
                <span className={styles.value}>{admin.email}</span>
              </div>
              <div className={styles.detailItem}>
                <span className={styles.label}>ID de Usuario:</span>
                <span className={styles.value}>{admin.pk_idUser}</span>
              </div>
              <div className={styles.detailItem}>
                <span className={styles.label}>Edad:</span>
                <span className={styles.value}>{calculateAge(admin.birthday)}</span>
              </div>
            </Col>
            <Col md={6}>
              <div className={styles.detailItem}>
                <span className={styles.label}>Teléfono:</span>
                <span className={styles.value}>{admin.phone || 'No disponible'}</span>
              </div>
              <div className={styles.detailItem}>
                <span className={styles.label}>Estado de la cuenta:</span>
                <span className={styles.value}>{admin.isActive ? 'Activa' : 'Inactiva'}</span>
              </div>
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default AdminDetails
