import React, { useState } from 'react'
import { Table, Card, CardBody, CardTitle, Button } from 'reactstrap'
import { FaEye, FaEdit, FaTrash, FaBan } from 'react-icons/fa'
import styles from './AdminList.module.css'
import useWindowSize from '../../../hooks/useWindowSize'
import AdminDetails from '../AdminDetails/AdminDetails'

const AdminList = ({ admins, onDelete, onToggleStatus, onEdit }) => {
  const { width } = useWindowSize()
  const isMobile = width <= 800

  const [selectedAdmin, setSelectedAdmin] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const handleViewDetails = admin => {
    setSelectedAdmin(admin)
    toggleModal()
  }

  const renderAdminCard = admin => (
    <Card
      key={admin.pk_idAdmin}
      className={styles.card}
    >
      <CardBody>
        <img
          src={admin.user.urlImg || 'https://via.placeholder.com/50'}
          alt='Profile'
          className={styles.cardImage}
        />
        <CardTitle tag='h5'>{`${admin.user.name} ${admin.user.lastName}`}</CardTitle>
        <p>ID: {admin.pk_idAdmin}</p>
        <p className={admin.user.isActive ? styles.activeStatus : styles.inactiveStatus}>{admin.user.isActive ? 'Activa' : 'Inactiva'}</p>
        <div className={styles.cardActions}>
          <Button
            color='primary'
            onClick={() => handleViewDetails(admin.user)}
            title='Ver'
          >
            <FaEye />
          </Button>
          <Button
            color='info'
            onClick={() => onEdit(admin)}
            title='Editar'
          >
            <FaEdit />
          </Button>
          <Button
            color='warning'
            onClick={() => onToggleStatus(admin.pk_idAdmin)}
            title={admin.user.isActive ? 'Desactivar' : 'Activar'}
          >
            <FaBan />
          </Button>
          <Button
            color='danger'
            onClick={() => onDelete(admin.pk_idAdmin)}
            title='Eliminar'
          >
            <FaTrash />
          </Button>
        </div>
      </CardBody>
    </Card>
  )

  const renderAdminTable = () => (
    <Table
      striped
      className={styles.adminTable}
    >
      <thead>
        <tr>
          <th>ID</th>
          <th>Imagen</th>
          <th>Nombre Completo</th>
          <th>Cuenta Activa</th>
          <th>Opciones</th>
        </tr>
      </thead>
      <tbody>
        {admins.map(admin => (
          <tr key={admin.pk_idAdmin}>
            <td>{admin.pk_idAdmin}</td>
            <td>
              <img
                src={admin.user.urlImg || 'https://via.placeholder.com/50'}
                alt='Profile'
                className={styles.profileImage}
              />
            </td>
            <td>{`${admin.user.name} ${admin.user.lastName}`}</td>
            <td>
              <span className={admin.user.isActive ? styles.activeStatus : styles.inactiveStatus}>
                {admin.user.isActive ? 'Activa' : 'Inactiva'}
              </span>
            </td>
            <td>
              <Button
                color='primary'
                onClick={() => handleViewDetails(admin.user)}
                title='Ver'
              >
                <FaEye />
              </Button>{' '}
              <Button
                color='info'
                onClick={() => onEdit(admin)}
                title='Editar'
              >
                <FaEdit />
              </Button>{' '}
              <Button
                color='warning'
                onClick={() => onToggleStatus(admin.pk_idAdmin)}
                title={admin.user.isActive ? 'Desactivar' : 'Activar'}
              >
                <FaBan />
              </Button>{' '}
              <Button
                color='danger'
                onClick={() => onDelete(admin.pk_idAdmin)}
                title='Eliminar'
              >
                <FaTrash />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )

  return (
    <div>
      {isMobile ? <div className={styles.cardContainer}>{admins.map(renderAdminCard)}</div> : renderAdminTable()}
      {selectedAdmin && (
        <AdminDetails
          admin={selectedAdmin}
          isOpen={isModalOpen}
          toggle={toggleModal}
        />
      )}
    </div>
  )
}

export default AdminList
