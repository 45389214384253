import React from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import PropTypes from 'prop-types'

const ChartCard = ({ title, description, children }) => {
  return (
    <Card className='bg-[#083A59]'>
      <CardBody>
        <CardTitle
          className='text-white text-lg font-semibold'
          tag='h5'
          title={description}
        >
          {title}
        </CardTitle>
        {children}
      </CardBody>
    </Card>
  )
}

ChartCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default ChartCard
