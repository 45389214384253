import React from 'react'
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap'
import styles from './AthleteDetails.module.css'

const AthleteDetails = ({ athlete, isOpen, toggle }) => {
  if (!athlete) return null

  const calculateAge = birthday => {
    if (!birthday) return 'No disponible'
    const ageDifMs = Date.now() - new Date(birthday).getTime()
    const ageDate = new Date(ageDifMs)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  const formatDate = date => {
    if (!date) return 'No disponible'
    return new Date(date).toLocaleDateString()
  }

  const formatDate1 = (date, isLastLogin = false) => {
    if (!date) return isLastLogin ? 'Sin entrar' : 'No disponible'
    return new Date(date).toLocaleDateString()
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size='lg'
    >
      <ModalHeader toggle={toggle}>Detalles del atleta</ModalHeader>
      <ModalBody>
        <div className={styles.container}>
          <div className={styles.imageContainer}>
            <img
              src={athlete.user.urlImg || 'https://via.placeholder.com/150'}
              alt='Perfil'
              className={styles.profileImage}
            />
          </div>

          <Row className={styles.datesContainer}>
            <Col md={6}>
              <div className={styles.dateInfo}>
                <p className={styles.dateLabel}>Cuenta creada</p>
                <p className={styles.date}>{formatDate(athlete.user.createAccount)}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className={styles.dateInfo}>
                <p className={styles.dateLabel}>Última sesión</p>
                <p className={styles.date}>{formatDate1(athlete.user.lastLogin, true)}</p>
              </div>
            </Col>
          </Row>

          <h2 className={styles.fullName}>{`${athlete.user.name} ${athlete.user.lastName}`}</h2>

          <Row className={styles.detailsContainer}>
            <Col md={6}>
              <div className={styles.detailItem}>
                <span className={styles.label}>Correo:</span>
                <span className={styles.value}>{athlete.user.email}</span>
              </div>
              <div className={styles.detailItem}>
                <span className={styles.label}>ID de Atleta:</span>
                <span className={styles.value}>{athlete.user.pk_idUser}</span>
              </div>
              <div className={styles.detailItem}>
                <span className={styles.label}>Edad:</span>
                <span className={styles.value}>{calculateAge(athlete.user.birthday)}</span>
              </div>
            </Col>
            <Col md={6}>
              <div className={styles.detailItem}>
                <span className={styles.label}>Teléfono:</span>
                <span className={styles.value}>{athlete.user.phone || 'No disponible'}</span>
              </div>
              <div className={styles.detailItem}>
                <span className={styles.label}>Estado de la cuenta:</span>
                <span className={styles.value}>{athlete.user.isActive ? 'Activa' : 'Inactiva'}</span>
              </div>
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default AthleteDetails
