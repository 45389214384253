import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { getAllAthletes, createAthlete, deleteAthlete, toggleAthleteStatus, updateAthlete } from '../services/athleteService'

const useAthleteDashboard = () => {
  const [athletes, setAthletes] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    fetchAthletes()
  }, [])

  const fetchAthletes = async () => {
    setLoading(true)
    const { success, data, message } = await getAllAthletes()
    if (success) {
      setAthletes(data)
      setError(null)
    } else {
      setError(message)
    }
    setLoading(false)
  }

  const toggleModal = () => setModal(!modal)

  const handleDelete = async athleteId => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este atleta?')) {
      const { success, message } = await deleteAthlete(athleteId)
      if (success) {
        toast.success(message)
        fetchAthletes()
      } else {
        toast.error(message)
      }
    }
  }

  const handleUpdate = async (athleteId, athleteData) => {
    try {
      const result = await updateAthlete(athleteId, athleteData)
      if (result.success) {
        setAthletes(
          athletes.map(athlete =>
            athlete.pk_idAthlete === athleteId ? { ...athlete, user: { ...athlete.user, ...result.data } } : athlete
          )
        )
        toast.success('Atleta actualizado con éxito')
        toggleModal()
      } else {
        toast.error(result.message)
      }
    } catch (error) {
      toast.error('Error al actualizar el atleta')
    }
  }

  const handleToggleStatus = async athleteId => {
    const { success, message } = await toggleAthleteStatus(athleteId)
    if (success) {
      toast.success(`Estado del atleta actualizado`)
      fetchAthletes()
    } else {
      toast.error(message)
    }
  }

  const handleSubmit = async formData => {
    try {
      const { success, message } = await createAthlete(formData)
      if (success) {
        toast.success('Atleta creado con éxito')
        toggleModal()
        fetchAthletes()
      } else {
        toast.error(message)
      }
    } catch (error) {
      toast.error('Error al crear el atleta')
    }
  }

  return {
    athletes,
    loading,
    error,
    modal,
    toggleModal,
    handleDelete,
    handleToggleStatus,
    handleSubmit,
    handleUpdate
  }
}

export default useAthleteDashboard
