import React from 'react'
import FormInput from '../../../common/FormInput'

const PhoneInput = ({ value, onChange, error }) => (
  <FormInput
    id='phone'
    label='Teléfono'
    type='tel'
    value={value}
    onChange={onChange}
    error={error}
  />
)

export default PhoneInput
