import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import Login from './pages/login/Login'
import AdminDashboard from './pages/dashboard/admin/AdminDashboard'
import AthleteDashboard from './pages/dashboard/athlete/AthleteDashboard'
import Home from './pages/home/Home.jsx'
import MainLayout from './layout/MainLayout'
import { ROUTES } from './constants/routes'

function App() {
  return (
    <div style={{ height: '100vh' }}>
      <Router>
        <Routes>
          <Route
            path={ROUTES.LOGIN}
            element={<Login />}
          />
          <Route element={<MainLayout />}>
            <Route
              path={ROUTES.ADMIN_DASHBOARD}
              element={<AdminDashboard />}
            />
            <Route
              path={ROUTES.ATLETA_DASHBOARD}
              element={<AthleteDashboard />}
            />
            <Route
              path={ROUTES.HOME}
              element={<Home />}
            />
          </Route>
        </Routes>
      </Router>
    </div>
  )
}

export default App
