// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AthleteDashboard_loading__1FQDI {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
}

.AthleteDashboard_error__YCxh9 {
  color: red;
  text-align: center;
  margin-top: 2rem;
}

.AthleteDashboard_title__tvryB {
  margin-bottom: 1rem;
}

.AthleteDashboard_addButton__KuV5I {
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/athlete/AthleteDashboard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".loading {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  font-size: 1.5rem;\n}\n\n.error {\n  color: red;\n  text-align: center;\n  margin-top: 2rem;\n}\n\n.title {\n  margin-bottom: 1rem;\n}\n\n.addButton {\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `AthleteDashboard_loading__1FQDI`,
	"error": `AthleteDashboard_error__YCxh9`,
	"title": `AthleteDashboard_title__tvryB`,
	"addButton": `AthleteDashboard_addButton__KuV5I`
};
export default ___CSS_LOADER_EXPORT___;
