import React from 'react'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import styles from './FormInput.module.css'

const FormInput = ({ id, label, type, value, onChange, error, ...rest }) => (
  <FormGroup className={styles.formGroup}>
    <Label
      for={id}
      className={styles.label}
    >
      {label}
    </Label>
    <Input
      id={id}
      name={id}
      type={type}
      value={value}
      onChange={e => onChange(e.target.value)}
      invalid={!!error}
      className={styles.input}
      {...rest}
    />
    {error && <FormFeedback className={styles.feedback}>{error}</FormFeedback>}
  </FormGroup>
)

export default FormInput
