import React from 'react'
import FormInput from '../../../common/FormInput'
import styles from './NameInput.module.css'

const NameInput = ({ name, lastName, onNameChange, onLastNameChange, errors }) => (
  <div className={styles.nameInputContainer}>
    <FormInput
      id='name'
      label='Nombre'
      type='text'
      value={name}
      onChange={onNameChange}
      error={errors.name}
    />
    <FormInput
      id='lastName'
      label='Apellido'
      type='text'
      value={lastName}
      onChange={onLastNameChange}
      error={errors.lastName}
    />
  </div>
)

export default NameInput
